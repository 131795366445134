body {
  margin: 0;
  font-family: 'Gothic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

th,
td {
  color: red
}

@font-face {
  font-family: 'Gothic';
  src: local('Gothic'), url(./fonts/Gothic/Gothic-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ExtraBoldGothic';
  src: local('ExtraBoldGothic'), url(./fonts/ExtraBoldGothic/ExtraBoldGothic-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'BoldGothic';
  src: local('BoldGothic'), url(./fonts/BoldGothic/BoldGothic-Regular.ttf) format('truetype');
}

::-webkit-input-placeholder {
  /* Chrome */
  color: '#EFEFEF';
  font-size: 0.85em;
}

.btn {
  text-transform: unset !important;
}

*:focus {
  outline: none;
}

.container {
  max-width: 100%;
  width: 100%;
  padding-block-start: 1em;
  font-family: 'Gothic';
}

.edit {
  margin: 0px auto;
  width: 100%;
  margin-top: 2em;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  font-family: 'Gothic';
}

.common {
  color: #191268;
  font-family: 'Gothic';
}

.InputField {
  font-family: 'Gothic';
  border: 2px solid;
  border-radius: 7px;
  font-weight: bold;
  font-size: 0.85em;
  height: 2.7em;
  padding-inline-start: 1em;
}

.Loading {
  position: absolute;
  left: 50%;
  top: 60%;
  z-index: 2;
}

.h1,h2,h3,h4,h5,h6 {
  font-family: 'Gothic';
}